import { combineReducers } from 'redux';

import { REDUCER_NAMESPACES } from 'src/constants';
import { RESET_APP } from './modules/app/app.duck';
import currency from './modules/currency/currency.duck';
import mspData from './modules/mspData/mspData.duck';
import psa from './modules/psa/psa.duck';

const submoduleReducer = (state, action) => {
  // https://alligator.io/redux/reset-state-redux/
  if (action.type === RESET_APP) {
    state = undefined;
  }

  return combineReducers({
    currency,
    mspData,
    psa
  })(state, action);
};

export const rootReducer = combineReducers({
  [REDUCER_NAMESPACES.SUBMODULE]: submoduleReducer
});

export default submoduleReducer;
