// @flow

import { createAction } from 'redux-actions';

import { wrapWithModule } from 'src/redux/utils';

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/app');
export const RESET_APP = wrapWithNamespace('RESET_APP');

export const resetApp = createAction(RESET_APP);
