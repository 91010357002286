// @flow

import { combineReducers } from 'redux';

import connections from './connections.duck';
import productMapping from './productMapping.duck';
import customerMapping from './customerMapping.duck';

// $FlowIgnore
export default combineReducers({
  connections,
  productMapping,
  customerMapping
});
